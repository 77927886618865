import React, { useEffect, useContext, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { confirmDialog } from 'primereact/confirmdialog';
import { NotificationContext, SessionContext } from 'contexts';
import { useNavigationLinks, useAuthorization } from 'hooks';
import { useBureauVotingForm } from 'hooks/voting';
import { requestStatus, voteType, votingStatus, decisionType } from 'constants/index';
import VotingFormHeader from '../../VotingForm/VotingFormHeader';
import VotingNominationList from '../../VotingForm/VotingNominationList';
import VotingNominationDetailsModal from '../../VotingForm/VotingNominationDetailsModal';
import SignoffToolbar from '../../VotingForm/SignoffToolbar';
import '../../VotingForm/VotingForm.css';

function BureauVoting() {
  const { showSuccess, showWarn, showError } = useContext(NotificationContext);
  const { checkPermission, checkCyclePermission, checkRole } = useAuthorization();
  const { goTo } = useNavigationLinks();
  const { sessionStore } = useContext(SessionContext);
  const { isBureauUser } = sessionStore;
  const { mode, navPostId, awardProcessTypeId,navNominationStatus, navCycleId } = useParams();
  const { formStore, dispatch, helperFunctions } = useBureauVotingForm();
  const { loadListRequest, loadCycleSummaryRequest, currentCycleId, isCycleDefined } = formStore;

  if (mode !== formStore.currentMode) {
    dispatch({ type: 'SET_CURRENT_MODE', data: mode });
    console.log('mode changed:', mode);
  }

  const isPending = useCallback((item) => item.votingStatus === votingStatus.PENDING, []);

  const onClickVote = (nominationId, vote) => {
    const nomination = helperFunctions.getNominationById(nominationId);
    if (vote === voteType.APPROVED || (nomination.newComment && nomination.newComment.trim())) {
      helperFunctions.sendVote(nominationId, vote);
      showSuccess(
        `${vote === voteType.NONE ? 'Your comment was successfully saved' : 'Your vote was successfully saved'}`
      );
      return;
    }
    if (!nomination.showCommentBox) {
      helperFunctions.onClickShowComments(nominationId);
    }

    if (vote === voteType.RECUSED && nomination.newComment !== '') {
      confirmDialog({
        message:
          'If you recuse yourself, you will no longer see this nomination and you will not be able to change your vote.  Are you sure you would like to recuse?',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        acceptClassName: 'p-button-danger',
        accept: () => helperFunctions.sendVote(nominationId, vote),
      });
    }

    showWarn('This vote requires you to provide a comment');
  };

  const onClickSignOff = async () => {
    if (helperFunctions.getSelected().length === 0) {
      showWarn('You should select at least one nomination.');
      return;
    }

    confirmDialog({
      message: 'Are you sure you want to sign off the selected nominations?',
      header: 'Sign Off Nomination',
      icon: 'pi pi-info-circle',
      acceptClassName: 'ui-button-success',
      accept: async () => {
        if (await helperFunctions.signOffSelected()) {
          showSuccess('The nominations were successfully signed off.');
        }
      },
    });
  };

  const onClickCheck = (event) => {
    const nominationId = event.value;
    const nominationToCheck = helperFunctions.getNominationById(nominationId);
    event.originalEvent.stopPropagation();
    console.log(nominationToCheck);
    helperFunctions.onClickCheck(nominationId);
    return false;
  };

  const onClickSelect = (flag) => {
    if (flag && formStore.filteredNominations.some((item) => isPending(item))) {
      showWarn('Nominations which are pending or has a tie vote result will not be selected.');
    }
    helperFunctions.onClickSelectAll(flag);
  };

  const onClickDecision = async (nominationId, decision) => {
    const nomination = helperFunctions.getNominationById(nominationId);
    if (
      [decisionType.DISAPPROVED].includes(decision) &&
      !(nomination.newComment && nomination.newComment.trim().length)
    ) {
      showWarn('This action requires you to provide a comment');
      helperFunctions.onClickShowComments(nomination.id);
      return;
    }

    let successMessage = 'Your decision was successfully saved.';
    if (decision === decisionType.NONE) {
      successMessage = 'Your comment was successfully saved.';
    }

    if (await helperFunctions.sendDecision(nominationId, decision)) {
      showSuccess(successMessage);
    }
  };

  const onClickSendForSignOff = async () => {
    const selectedNominations = helperFunctions.getSelected();
    if (selectedNominations.length > 0) {
       await helperFunctions.moveToSignOff(selectedNominations);
    }
  };

  useEffect(() => {
    if (helperFunctions.isSignOffMode()) {
      if ((
        !checkPermission('can_signoff_bureau') &&
        currentCycleId &&
        !checkCyclePermission('can_signoff_bureau', currentCycleId)
      ) && !checkPermission('can_push_to_chair')) {
        goTo('not_authorized');
      }
    } else if (
      !checkPermission('can_vote_bureau') &&
      currentCycleId &&
      !checkCyclePermission('can_vote_bureau', currentCycleId) &&
      !checkPermission('can_push_to_chair')
    ) {
      goTo('not_authorized');
    }
  }, [checkCyclePermission, checkPermission, currentCycleId, goTo, helperFunctions]);

  useEffect(() => {
    const loadListNominations = async (nav) => {
      await helperFunctions.loadNominationsPendingSignoff();
      helperFunctions.loadCurrentUser();
    };
    const loadCycleSummary = async () => {
      await helperFunctions.loadCycleSummary();
    };

    if (!currentCycleId && !isCycleDefined) {
      helperFunctions.setCurrentCycle(0);
    }
    if (currentCycleId && loadListRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadListNominations();
    }
    if (currentCycleId && loadCycleSummaryRequest === requestStatus.REQUEST_NOT_INITIATED) {
      loadCycleSummary();
    }
  }, [currentCycleId, helperFunctions, isCycleDefined, loadCycleSummaryRequest, loadListRequest]);

  useEffect(() => {
    if (!isBureauUser) {
      goTo('not_authorized');
    }
  }, [goTo, isBureauUser]);

  if (!currentCycleId && isCycleDefined) {
    return (
      <>
        <h4>{helperFunctions.getTitle()}</h4>
        <div className="container">
          <div className="alert alert-danger d-flex justify-content-center align-items-center" role="alert">
            <FontAwesomeIcon icon={faExclamationCircle} size="2x" className="mr-2" />
            There is currently no cycle available. Please contact your Bureau Awards Coordinator for more information.
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      <h4>{helperFunctions.getTitle()}</h4>
      <VotingFormHeader formStore={formStore} helperFunctions={helperFunctions} />

      {!helperFunctions.isSignOffMode() && checkRole('bureau_coordinator') && (
        <div className="row">
          <div className="col-3 text-center classWithPad">
            <Button
              label="Send Selected Nominations for Signoff"
              icon="pi pi-check"
              className="w-100 p-button-primary"
              disabled={
                !helperFunctions.getSelected() || 
                helperFunctions.getSelected().length === 0 || 
                helperFunctions.getSelected().some(x => x.summary?.totalVoted === 0)
              }
              onClick={onClickSendForSignOff}
            />
          </div>
        </div>
      )}

      {helperFunctions.isSignOffMode() && (
        <SignoffToolbar
          formStore={formStore}
          helperFunctions={helperFunctions}
          onClickSelect={onClickSelect}
          onClickStatusFilter={helperFunctions.onClickStatusFilter}
          onClickSignOff={onClickSignOff}
        />
      )}

      <VotingNominationList
        formStore={formStore}
        helperFunctions={helperFunctions}
        onClickCheck={(e) => onClickCheck(e)}
        onClickVote={onClickVote}
        onClickDecision={onClickDecision}
        onClickTypeFilter={helperFunctions.onClickTypeFilter}
      />

      <VotingNominationDetailsModal
        isModalOpened={formStore.detailModalOpened}
        nomination={formStore.detailedNomination}
        onClickClose={() => dispatch({ type: 'CLOSE_NOMINATION_DETAILS_MODAL' })}
      />
      <div className="debug mt-3">
        debug Info =&gt; form Mode:
        {mode}
      </div>
    </>
  );
}

export default BureauVoting;
