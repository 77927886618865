import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/pro-solid-svg-icons';
import { Button } from 'primereact/button';

import { SkeletonTable } from 'components';
import { requestStatus, nominationAction, votingStatus } from 'constants/index';
import Loading from 'views/Shared/Loading';
import SignOffComment from './SignOffComment';
import SignOffNominationItem from './SignOffNominationItem';
import SignOffNominationDetails from './SignOffNominationDetails';

function SignOffNominationsList({ formStore, helperFunctions, onClickCheck, onClickEndorse, onClickApplyChanges }) {
  const isProcessing = formStore.signOffRequest === requestStatus.REQUEST_IN_PROGRESS;
  const isLoading = formStore.loadListRequest === requestStatus.REQUEST_IN_PROGRESS;
  const [clickProcessing, setClickProcessing] = useState(false);

  if (isLoading) {
    return <SkeletonTable rowsSize={5} />;
  }

  const renderActions = (item) => {
    const buttons = [];

    if (helperFunctions.isExecMode()) {
      buttons.push(
        <Button
          key="change_nomination_btn"
          label={item.votingStatus === votingStatus.DISAPPROVED ? 'Override & Approve' : 'Change'}
          icon={item.votingStatus === votingStatus.DISAPPROVED ? 'pi pi-times-circle' : 'pi pi-bolt'}
          className={`w-100 p-button-sm ${
            item.votingStatus === votingStatus.DISAPPROVED ? 'p-button-danger' : 'p-button-success'
          }  mt-2`}
          onClick={() => helperFunctions.onChangeButtonClick(item.id)}
        />
      );
    }

    if (item.isNominationChanged) {
      buttons.push(
        <>
        <Button
          label="Save"
          icon="pi pi-save"
          className="w-100 p-button-sm p-button-primary mt-2"
          onClick={() => {
            onClickApplyChanges(item, nominationAction.CHANGE);
          }}
        />
        <Button
          label="Save & Sign Off"
          icon="pi pi-check"
          className="w-100 p-button-sm p-button-primary mt-2"
          onClick={() => {
            onClickApplyChanges(item, nominationAction.FORWARD);
          }}
        />
        </>
        
      );
    } else {
      if (helperFunctions.isFinanceMode()) {
        buttons.push(
          <Button
            key="save_fiscal_strip"
            label="Save Changes"
            icon="pi pi-save"
            className="w-100 mt-2 p-button-sm p-button-primary"
            disabled={!item.isFiscalStripDirty}
            onClick={() => onClickApplyChanges(item, nominationAction.NONE)}
          />
        );
      }
      if (
        helperFunctions.isExecMode() ||
        helperFunctions.isFinanceMode() ||
        helperFunctions.isAnytimeMode() ||
        helperFunctions.isManagementMode()
      ) {
        const action = item.isNominationChanged ? nominationAction.NONE : nominationAction.FORWARD;
        buttons.push(
          <Button
            key="endorse_btn"
            label={
              helperFunctions.isFinanceMode()
                ? 'Sign Off'
                : `Endorse ${item.votingStatus === votingStatus.DISAPPROVED ? 'Disapproval' : 'Approval'}`
            }
            icon="pi pi-thumbs-up"
            className="w-100 p-button-sm p-button-success mt-2"
            disabled={clickProcessing}
            onClick={async () => {
              setClickProcessing(true);
              await onClickEndorse(item, action);
              setClickProcessing(false);
            }}
          />
        );
      }

      if (helperFunctions.isFinanceMode()) {
        buttons.push(
          <Button
            key="return_to_analyst_btn"
            label="Return to Financial Analyst"
            icon="pi pi-directions-alt"
            className="w-100 mt-2 p-button-sm p-button-warning"
            onClick={() => onClickEndorse(item, nominationAction.RETURN)}
          />
        );
      }

      if (helperFunctions.isExecMode() || helperFunctions.isManagementMode() || helperFunctions.isAnytimeMode()) {
        if (!(helperFunctions.isExecMode() && item.votingStatus === votingStatus.DISAPPROVED))
          buttons.push(
            <Button
              key="reject_btn"
              label="Reject"
              icon="pi pi-times-circle"
              className="w-100 mt-2 p-button-sm p-button-danger"
              onClick={() => onClickEndorse(item, nominationAction.REJECT)}
            />
          );
        buttons.push(
          <Button
            key="return_btn"
            label="Return to HR"
            icon="pi pi-directions-alt"
            className="w-100 mt-2 p-button-sm p-button-warning"
            onClick={() => onClickEndorse(item, nominationAction.RETURN)}
          />
        );
      }
    }

    return buttons;
  };

  return (
    <>
      {formStore.filteredNominations.length > 0 && (
        <div className="mt-3 mx-2">
          <div className="row">
            <div className="col-2 font-weight-bold">
              <span className="pl-4">ID</span>
            </div>
            <div className="col-2 font-weight-bold text-center">Type</div>
            <div className="col-2 font-weight-bold text-center">Amount</div>
            <div className="col-3 font-weight-bold text-center">Sign Offs</div>
          </div>
        </div>
      )}
      {formStore.loadListRequest === requestStatus.REQUEST_COMPLETED && !formStore.filteredNominations.length && (
        <div className="container mt-3">
          <div className="alert alert-warning d-flex justify-content-center align-items-center" role="alert">
            <FontAwesomeIcon icon={faExclamationCircle} size="2x" className="mr-2" />
            No nomination match the filter configurations, please check your filters.
          </div>
        </div>
      )}

      <Accordion
        multiple
        className="mt-2 nomination-accordion-item"
        onTabOpen={(e) => {
          helperFunctions.toggleAccordionActiveIndex(e.index);
          return helperFunctions.loadDetails(e.index);
        }}
        onTabClose={(e) => helperFunctions.toggleAccordionActiveIndex(e.index)}
        onTabChange={(e) => helperFunctions.setAccordionActiveIndexes(e.index)}
        activeIndex={formStore.accordionActiveIndexes}
      >
        {formStore.filteredNominations.map((item) => (
          <AccordionTab
            key={`_accordion_${item.id}`}
            className="border-top"
            headerClassName="w-100"
            header={
              <SignOffNominationItem nomination={item} helperFunctions={helperFunctions} onClickCheck={onClickCheck} />
            }
          >
            <Loading
              local
              active={!item.detailsLoaded || (isProcessing && formStore.requestNominationId === item.id)}
              type="ring"
              color="gray"
            />
            <div className="row">
              <div className={`row anim-transition ${item.showCommentBox ? 'col-10' : 'col-12'}`}>
                <div className="col-10">
                  <SignOffNominationDetails
                    formStore={formStore}
                    nomination={item}
                    helperFunctions={helperFunctions}
                    onClickMoreDetails={helperFunctions.onClickMoreDetails}
                  />
                </div>
                <div className="col-2 d-flex flex-column justify-content-center">
                  <div className="text-center mt-2">{renderActions(item)}</div>
                  <SignOffComment
                    comment={item.comment}
                    onChangeComment={(value) => helperFunctions.onChangeComment(item.id, value)}
                  />
                </div>
              </div>
            </div>
          </AccordionTab>
        ))}
      </Accordion>
    </>
  );
}
SignOffNominationsList.propTypes = {
  formStore: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
  onClickCheck: PropTypes.func.isRequired,
  onClickEndorse: PropTypes.func.isRequired,
  onClickApplyChanges: PropTypes.func.isRequired,
};
export default SignOffNominationsList;
