const nominationStatus = {
  NONE: 0,
  DRAFT: 100,
  PENDING_REWRITE: 200,
  PENDING_USDH_APPROVAL: 300,
  PENDING_HR_REVIEW: 400,
  PENDING_JCAC_SIGNOFF: 500,
  PENDING_HR_ENDORSEMENT: 600,
  PENDING_EXEC_SIGNOFF: 700,
  PENDING_EXTERNAL_APPROVAL: 800,
  PENDING_ADDITIONAL_APPROVAL: 900,
  PENDING_MANAGEMENT_APPROVAL: 1000,
  PENDING_FINANCIAL_SIGNOFF: 1100,
  PENDING_PAYROLL_ACTION: 1200,
  PENDING_FINANCIAL_INFORMATION: 1300,
  PENDING_EFFECTIVE_DATE: 1400,
  PENDING_SEND_TO_OPS: 1500,
  PENDING_ANYTIME_APPROVER: 1600,
  PENDING_BUREAU_REVIEW: 1700,
  PENDING_BUREAU_COMMITTEE_VOTE: 1750,
  PENDING_BUREAU_COMMITTEE_CHAIR: 1800,
  BUREAU_APPROVED: 1850,
  BUREAU_DISAPPROVED: 1860,
  BUREAU_DOWNGRADED: 1870,
  PENDING_POST_REVIEW: 1900,
  COMPLETED: 2000,
  DISAPPROVED: 2100,
  DISAPPROVED_BY_SUPERVISOR: 2200,
  DISAPPROVED_BY_EXTERNAL : 2220,
  PENDING_POST_PROCESSING: 2300,
  DELETED: 2900,
};

export default nominationStatus;
