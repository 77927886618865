import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import orderBy from 'lodash/orderBy';
import { Checkbox } from 'primereact/checkbox';
import { formOfRecognition, nominationStatus, approvalStatus, processType } from 'constants/index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlagAlt, faThumbsUp as fasThumbsUp, faThumbsDown as fasThumbsDown } from '@fortawesome/pro-solid-svg-icons';
import { TypeBadge } from 'components';

import votingStatus from 'constants/votingStatus';

function SignOffNominationItem({ nomination, helperFunctions, onClickCheck }) {
  const getColorState = useCallback((status) => {
    let colorClass = 'text-warning';
    if (status === votingStatus.APPROVED) colorClass = 'text-success';
    if (status === votingStatus.DISAPPROVED) colorClass = 'text-danger';
    if (status === votingStatus.DOWNGRADED) colorClass = 'text-primary';
    return colorClass;
  }, []);

  const getSignOffBy = (role) => {
    let dateProp = 'financeApprovalDate';
    let nameProp = 'financeOfficerName';
    if (role === 'EXEC') {
      dateProp = 'execApprovalDate';
      nameProp = 'execOfficerName';
    }
    if (
      role !== 'EXEC' &&
      (nomination.formOfRecognition === formOfRecognition.TIMEOFF_ONLY ||
        (nomination.formOfRecognition === formOfRecognition.CASH_ONLY && nomination.approvedAmount === 0))
    ) {
      return null;
    }
    if (!nomination[dateProp]) {
      if (nomination.nominationStatus === nominationStatus.PENDING_FINANCIAL_INFORMATION) {
        return (
          <div className="text-danger font-italic">
            {role === 'EXEC' ? `Pending ${role} sign off` : `Pending ${role} entry`}
          </div>
        );
      }
      return <div className="text-danger font-italic">{`Pending ${role} sign off`}</div>;
    }
    return <div className="text-success">{`Signed Off by ${nomination[nameProp]} (${role})`}</div>;
  };

  const getStatusIcon = useCallback((status) => {
    if (status === votingStatus.APPROVED) return fasThumbsUp;
    if (status === votingStatus.DISAPPROVED) return fasThumbsDown;
    return faFlagAlt;
  }, []);

  const getSignOffs = () => {
    if (nomination.awardProcessType === processType.ANNUAL) {
      if (nomination.nominationStatus === nominationStatus.PENDING_FINANCIAL_INFORMATION) {
        return (
          <>
            <div>{getSignOffBy('ANALYST')}</div>
            <div className="mt-1">{getSignOffBy('EXEC')}</div>
          </>
        );
      }
      if (
        nomination.nominationStatus === nominationStatus.PENDING_EXEC_SIGNOFF &&
        nomination.votingStatus === votingStatus.DISAPPROVED
      ) {
        return <div className="mt-1">{getSignOffBy('EXEC')}</div>;
      }
      return (
        <>
          <div>{getSignOffBy('FMO')}</div>
          <div className="mt-1">{getSignOffBy('EXEC')}</div>
        </>
      );
    }
    if (nomination.nominationStatus === nominationStatus.PENDING_ANYTIME_APPROVER) {
      const anytimeApprovals = nomination.approvals.filter((item) => item.roleKey === 'AnytimeApprover');
      return orderBy(anytimeApprovals, ['order'], ['asc']).map((item) => {
        if (item.approvalStatus === approvalStatus.APPROVED) {
          return <div key={item.id} className="text-success">{`Signed Off by ${item.employeeName}`}</div>;
        }
        return <div key={item.id} className="text-danger font-italic">{`Pending ${item.employeeName} sign off`}</div>;
      });
    }
    return null;
  };

  const getAmountUnit = (option) => {
    if (option === formOfRecognition.CASH_ONLY) return 'USD';
    if (option === formOfRecognition.TIMEOFF_ONLY) return 'Hours';
    return '';
  };

  const isSelectable = (item) => {
    return !(helperFunctions.isExecMode() && item.votingStatus === votingStatus.DISAPPROVED);
  };

  return (
    <div className="container-fluid py-2 px-0">
      <div className="row mx-0">
        <div className="col-2 text-nowrap pl-0">
          <Checkbox
            className="mr-1 pt-1"
            value={nomination.id}
            checked={nomination.selected}
            onChange={onClickCheck}
            onMouseDown={(e) => e.stopPropagation()}
            // disabled={!isSelectable(nomination)}
          />
          {nomination.nominationKey || ''}
          {helperFunctions.isSignOffMode() && (
            <FontAwesomeIcon
              icon={getStatusIcon(nomination.votingStatus)}
              className={`ml-2 ${getColorState(nomination.votingStatus)}`}
            />
          )}
        </div>
        <div className="col-2 text-center">
          {nomination.awardTypeName}
          <TypeBadge type={nomination.awardProcessType} />
        </div>
        <div className="col-2 text-center">
          {helperFunctions.isManagementMode() ? nomination.recommendedAmount : nomination.approvedAmount}
          <div>{getAmountUnit(nomination.formOfRecognition)}</div>
        </div>

        <div className="col-3 text-center">{getSignOffs()}</div>
      </div>
    </div>
  );
}
SignOffNominationItem.propTypes = {
  nomination: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
  onClickCheck: PropTypes.func.isRequired,
};

export default SignOffNominationItem;
