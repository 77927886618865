import { useContext, useReducer, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import xor from 'lodash/xor';
import nextId from 'react-id-generator';

import { getDateUtcNow } from 'utils/dateHelpers';
import { SessionContext } from 'contexts';
import { useNominationService, useNominationValidation } from 'hooks/nomination';
import { useCertificateService } from 'hooks';

import {
  requestStatus,
  responseStatus,
  nominationAction,
  formOfRecognition,
  nominationFormMode,
  processType,
} from 'constants/index';
import { nominationFormReducer, initNominationForm } from 'reducers';

function useNominationForm() {
  const { sessionStore } = useContext(SessionContext);
  const { mode, nominationId } = useParams();
  const { validate } = useNominationValidation();
  const service = useNominationService();

  const [formStore, dispatch] = useReducer(nominationFormReducer, initNominationForm);
  const { employeeId, missionId, isBureauUser } = sessionStore.employeeUser;
  const { currentMissionId } = sessionStore;
  const certificateService = useCertificateService();

  const formMode = mode.toLowerCase();

  const getFormMode = useCallback(() => {
    switch (formMode) {
      case 'new':
        return nominationFormMode.NEW;
      case 'edit':
        return nominationFormMode.EDIT;
      case 'view':
        return nominationFormMode.VIEW;
      case 'lesview':
        return nominationFormMode.LESVIEW;
      case 'approve':
        return nominationFormMode.APPROVE;
      case 'review':
        return nominationFormMode.REVIEW;
      case 'completed':
        return nominationFormMode.COMPLETED;
      case 'disapproved':
        return nominationFormMode.DISAPPROVED;
      case 'externalapproval':
        return nominationFormMode.EXTERNAL_APPROVAL;
      case 'financialinfo':
        return nominationFormMode.FINANCIAL_INFO;
      case 'sendtoops':
        return nominationFormMode.SEND_TO_OPS;
      case 'bureaureview':
        return nominationFormMode.BUREAU_REVIEW;
      case 'bureauVoting':
        return nominationFormMode.BUREAU_VOTING;
      case 'bureauongoing':
        return nominationFormMode.BUREAU_ONGOING;
      case 'bureaudecision':
      return nominationFormMode.BUREAU_APPROVAL;
      default:
        return nominationFormMode.VIEW;
    }
  }, [formMode]);

  const getNominationId = () =>
    formStore.nominationIdforPrintModal ??
    (typeof nominationId === 'string' ? parseInt(nominationId, 10) : nominationId);

  const getMode = () => formMode;
  const isNewMode = useCallback(() => getFormMode() === nominationFormMode.NEW, [getFormMode]);
  const isEditMode = useCallback(() => getFormMode() === nominationFormMode.EDIT, [getFormMode]);
  const isViewMode = useCallback(() => getFormMode() === nominationFormMode.VIEW, [getFormMode]);
  const isApproveMode = useCallback(() => getFormMode() === nominationFormMode.APPROVE, [getFormMode]);
  const isReviewMode = useCallback(() => getFormMode() === nominationFormMode.REVIEW, [getFormMode]);
  const isCompletedMode = useCallback(() => getFormMode() === nominationFormMode.COMPLETED, [getFormMode]);
  const isDisapprovedMode = useCallback(() => getFormMode() === nominationFormMode.DISAPPROVED, [getFormMode]);
  const isFinancialInfoMode = useCallback(() => formMode === 'financialinfo', [formMode]);
  const isPendingEffectiveDateMode = useCallback(() => formMode === 'pendingeffectivedate', [formMode]);
  const isSendToOps = useCallback(() => formMode === 'isSendToOps', [formMode]);
  const isExternalApprovalMode = useCallback(
    () => getFormMode() === nominationFormMode.EXTERNAL_APPROVAL,
    [getFormMode]
  );
  const isPrintIconVisible = useCallback(() => {
    return isCompletedMode() || isDisapprovedMode() || isExternalApprovalMode();
  }, [isCompletedMode, isExternalApprovalMode, isDisapprovedMode]);
  const getTitle = useCallback(() => {
    switch (formMode) {
      case 'new':
        return 'New Nomination';
      case 'edit':
        return 'Edit Nomination';
      case 'view':
        return 'View Nomination';
      case 'approve':
        return 'Supervisor - Approve Nomination';
      case 'review':
        return 'HR - Review Nomination';
      case 'financialinfo':
        return 'Finance - Pending Fiscal Data';
      case 'pendingeffectivedate':
        return 'HR - Approved Nomination';
      case 'ongoing':
        return 'HR - Ongoing Nomination';
      case 'disapproved':
        return 'HR - Disapproved Nomination';
      default:
        return 'Form Nomination';
    }
  }, [formMode]);

  const helperFunctions = {
    // reason and type
    onClickCancelReasonAndTypeModal: () => dispatch({ type: 'CANCEL_REASON_AND_TYPES_MODAL' }),
    onClickOpenReasonAndTypeModal: () => dispatch({ type: 'OPEN_REASON_AND_TYPES_MODAL' }),
    onClickOkReasonAndTypeModal: () => dispatch({ type: 'CLOSE_REASON_AND_TYPES_MODAL' }),
    onClickToggleAwardType: (type) =>
      dispatch({
        type: 'SET_SELECTED_TYPE',
        data: {
          awardTypeId: type.id,
          awardTypeName: type.name,
          awardProcessType: type.awardProcessType,
          proposedFormOfRecognition:
            mode === 'new' || mode === 'edit' ? null : formStore.nomination.proposedFormOfRecognition,
          // formOfRecognition: mode === 'new' || mode === 'edit' ? null : type.formOfRecognitionType,
          formOfRecognition: null,
          // formOfRecognition: mode === 'new' || mode === 'edit' ? null : formStore.nomination.formOfRecognition,
          // selectedFormOfRecognition: type.formOfRecognitionType,
        },
      }),
    onClickToggleAwardReason: (reason) =>
      dispatch({
        type: 'SET_SELECTED_REASONS',
        data: xor(formStore.nomination.awardReasons, [reason.id]),
      }),
    onChangeOtherReason: (value) => dispatch({ type: 'SET_OTHER_REASON', data: value }),

    // comments
    onClickShowHideComments: () => {
      helperFunctions.loadHistoryAndComments();
      dispatch({ type: 'TOGGLE_RIGHT_SIDEBAR' });
    },
    onClickShowHideRejectedNominees: () => dispatch({ type: 'TOGGLE_REJECTED_NOMINEES_VISIBILITY' }),

    // add nominees / search modal
    onClickOpenEmployeeSearchModal: () => dispatch({ type: 'OPEN_EMPLOYEE_SEARCH_MODAL' }),
    onClickCloseEmployeeSearchModal: () => dispatch({ type: 'CLOSE_EMPLOYEE_SEARCH_MODAL' }),
    onClickCloseHistoryModal: () => dispatch({ type: 'CLOSE_EMPLOYEE_HISTORY_MODAL' }),
    addSelectedNominees: (records) => {
      dispatch({ type: 'ADD_NOMINEES', data: records });
      dispatch({ type: 'UPDATE_GROUP_FLAG' });
    },
    removeNomineeIdx: (idx) => {
      dispatch({ type: 'REMOVE_NOMINEE_IDX', data: idx });
      dispatch({ type: 'UPDATE_GROUP_FLAG' });
    },
    updateNominee: (nominee) => dispatch({ type: 'UPDATE_NOMINEE', data: nominee }),
    updateNotEligibleNominee: (nominee) => dispatch({ type: 'UPDATE_NOMINEE', data: nominee }),

    onClickToggleEditPosition: (nominee) => dispatch({ type: 'TOGGLE_NOMINEE_EDIT_POSITION', data: nominee }),
    onChangePreviousPosition: (nominee, previousPositionTitle) =>
      dispatch({
        type: 'UPDATE_PREVIOUS_POSITION',
        data: { employeeId: nominee.employeeId, previousPositionTitle },
      }),

    // justification and citation
    onChangeJustification: (value) => dispatch({ type: 'UPDATE_JUSTIFICATION', data: value }),
    resetJustificationFlag: () => dispatch({ type: 'RESET_JUSTIFICATION_FLAG' }),
    resetCitationFlag: () => dispatch({ type: 'RESET_CITATION_FLAG' }),
    setAutoSave: (value) => dispatch({ type: 'SET_AUTO_SAVE', data: value }),
    onChangeCitation: (value) => dispatch({ type: 'UPDATE_CITATION', data: value }),
    updateJustificationSaved: (value) => {
      dispatch({ type: 'UPDATE_JUSTIFICATION_SAVED', data: value });
    },
    updateCitationSaved: (value) => {
      dispatch({ type: 'UPDATE_CITATION_SAVED', data: value });
    },

    onChangeCycle: (value) => dispatch({ type: 'UPDATE_CYCLE', data: value }),
    onChangeBureauCycle: (value) => dispatch({ type: 'UPDATE_BUREAU_CYCLE', data: value }),

    // form of recognition
    onChangeFormOfRecognition: (value) => dispatch({ type: 'UPDATE_FORM_OF_RECOGNITION', data: value }),
    onChangePeriodOfPerformance: (value, type) =>dispatch({type: `${type === 'start' ? 'UPDATE_PERIOD_OF_PERFORMANCE_START_DATE' : 'UPDATE_PERIOD_OF_PERFORMANCE_END_DATE'}`, data: value}),
    onChangeProposedAmount: (value) => dispatch({ type: 'UPDATE_PROPOSED_AMOUNT', data: value }),
    onChangeRecommendedAmount: (value) => dispatch({ type: 'UPDATE_RECOMMENDED_AMOUNT', data: value }),
    // financial info
    onChangeFiscalStrip: (value) => dispatch({ type: 'UPDATE_FISCALSTRIP', data: value }),
    onChangeApprovedAmount: (value) => dispatch({ type: 'UPDATE_APPROVED_AMOUNT', data: value }),
    onChangeIndividualFiscalStrip: (nomId, nominee, fiscalStrip) =>
      dispatch({
        type: 'UPDATE_INDIVIDUAL_FISCAL_STRIP',
        data: { employeeId: nominee.employeeId, fiscalStrip, nomId },
      }),

    onClickAssignFiscalStripToAllNominees: (nomId, value) =>
      dispatch({
        type: 'UPDATE_FISCAL_STRIP_TO_ALL_NOMINEES',
        data: { nomId, value },
      }),

    // updated for modal
    openFiscalStripModal: (nomineeEmployeeId, nomineeFiscalStrip) => {
      dispatch({
        type: 'OPEN_FISCAL_STRIP_MODAL',
        data: { nomineeEmployeeId, nomineeFiscalStrip },
      });
    },

    closeFiscalStripModal: () => {
      dispatch({ type: 'CLOSE_FISCAL_STRIP_MODAL' });
    },

    onUpdateFiscalStrip: async (fiscalStripData) => {
      const temp = Object.values(fiscalStripData.data);
      temp.forEach((v, i) => {
        if (!temp[i]) {
          temp[i] = '';
        }
      });
      const assembledFiscalStrip = temp.join('/');

      dispatch({
        type: 'UPDATE_INDIVIDUAL_FISCAL_STRIP',
        data: {
          nomineeEmployeeId: fiscalStripData.nomineeEmployeeId,
          fiscalStrip: assembledFiscalStrip,
          nominationId: fiscalStripData.nominationId,
        },
      });
    },

    // anytime approvers modal
    onClickCloseApproverModal: () => dispatch({ type: 'CLOSE_NOMINATION_APPROVER_MODAL' }),

    // send to ops
    onClickOpenSendToOpsModal: () => dispatch({ type: 'OPEN_SEND_TO_OPS_MODAL' }),
    onClickCloseSendToOpsModal: () => dispatch({ type: 'CLOSE_SEND_TO_OPS_MODAL' }),

    onClickCloseCertificatePrintModal: () => {
      dispatch({ type: 'CLOSE_CERTIFICATE_PRINT_MODAL' });
    },

    onOpenMessageModalClick: () => {
      dispatch({ type: 'SET_MESSAGE_MODAL_OPEN' });
    },

    onCloseMessageModalClick: () => {
      dispatch({ type: 'SET_MESSAGE_MODAL_CLOSE' });
    },

    onClickOpenCertificatePrintModal: () => {
      dispatch({ type: 'OPEN_CERTIFICATE_PRINT_MODAL' });
    },

    onClickOpenPrintModal: (currentNominationId, currentAwardProcessType) =>
      dispatch({
        type: 'OPEN_PRINT_FORM_MODAL',
        data: { currentNominationId, currentAwardProcessType },
      }),
    onClickClosePrintModal: () => dispatch({ type: 'CLOSE_PRINT_FORM_MODAL' }),

    onClickOpenReturnToModal: () => dispatch({ type: 'OPEN_RETURN_TO_MODAL' }),

    onClickOpenModalForReject: (actionButton) =>
      dispatch({
        type: 'OPEN_CONFIRMATION_MODAL',
        data: {
          title: 'Confirm Rejection',
          isCommentRequired: true,
          actionButton,
        },
      }),
    onClickOpenModalForDisapproveNominee: (actionButton) =>
      dispatch({
        type: 'OPEN_CONFIRMATION_MODAL',
        data: {
          title: 'Confirm Disapproval',
          isCommentRequired: true,
          actionButton,
        },
      }),

    onClickCloseNominationRejectModal: () => dispatch({ type: 'CLOSE_CONFIRMATION_MODAL' }),

    onClickCloseReturnToModal: () => dispatch({ type: 'CLOSE_RETURN_TO_MODAL' }),

    onClickGenerateCertificate: (currentNominationId, currentAwardProcessType) =>
      dispatch({
        type: 'GENERATE_CERTIFICATE',
        data: { currentNominationId, currentAwardProcessType },
      }),

    clearFormMessage: () => dispatch({ type: 'CLEAR_VALIDATION_RESULT' }),

    getUnitSymbol: (option) => {
      let unitOfSymbol = '';
      if (option === formOfRecognition.CASH_ONLY) {
        unitOfSymbol = 'USD';
      }

      if (option === formOfRecognition.TIMEOFF_ONLY) {
        unitOfSymbol = 'Hours';
      }

      return unitOfSymbol;
    },

    // notes & comments
    onClickAddNote: async (note) => {
      note.id = 0;
      note.indexId = nextId('new-note-');
      note.nominationId = getNominationId();
      await service.processComment(note);
      dispatch({ type: 'ADD_NOTE', data: [note] });
      helperFunctions.loadHistoryAndComments(true);
    },
    onClickRemoveNote: async (indexId) => {
      const idx = formStore.nomination.notes.findIndex((note) => note.indexId === indexId);
      const note = formStore.nomination.notes[idx];
      note.nominationId = getNominationId();
      note.noteState = 'deleted';
      dispatch({ type: 'REMOVE_NOTE_IDX', data: idx });
    },
    onChangeConfirmationComment: (value) => dispatch({ type: 'UPDATE_CONFIRMATION_COMMENT', data: value }),

    getNewNote: (commentValue) => ({
      userEmployeeId: sessionStore.employeeUser.employeeId,
      userName: sessionStore.employeeUser.name,
      content: commentValue.trim(),
      createdDate: getDateUtcNow(),
      noteType: 'comment',
      noteState: 'new',
    }),

    // load and save
    saveNomination: async (action) => {
      if (!formStore.nomination.isAutoSave) {
        const validationResult = await validate(formStore.nomination, action === nominationAction.FORWARD);
        if (!validationResult.isValid) {
          dispatch({
            type: 'UPDATE_VALIDATION_RESULT',
            data: validationResult.errors,
          });
          return false;
        }
      }

      const nomineesList = formStore.nomination.nominees.map((item) => ({
        id: item.id,
        nominationId: formStore.nomination.nominationId,
        employeeId: item.employeeId,
        fiscalStrip: item.fiscalStrip,
        previousPositionTitle: item.previousPositionTitle,
      }));

      const nominationData = {
        nominationId: formStore.nomination.nominationId || 0,
        userEmployeeId: employeeId,
        awardReasons: formStore.nomination.awardReasons.map((item) => ({
          awardReasonId: item,
        })),
        awardTypeId: formStore.nomination.awardTypeId,
        awardTypeName: formStore.nomination.awardTypeName,
        otherReason: formStore.nomination.otherReason,
        nominatorId: formStore.nomination.nominatorUserId,
        missionId: formStore.nomination.missionId ?? currentMissionId,
        postId: formStore.nomination.postId,
        sectionId: formStore.nomination.sectionId,
        cycleId: formStore.nomination.awardProcessType === processType.ANNUAL ? formStore.nomination.cycleId : null,
        bureauId: formStore.nomination.bureauId,
        bureauCycleId: formStore.nomination.bureauCycleId,
        citation: formStore.nomination.citation,
        justification: formStore.nomination.justification,
        periodOfPerformanceStartDate: formStore.nomination.periodOfPerformanceStartDate,
        periodOfPerformanceEndDate: formStore.nomination.periodOfPerformanceEndDate,
        formOfRecognition:
          formStore.nomination.formOfRecognition == null
            ? formOfRecognition.NOCASH_AND_NOTIMEOFF
            : formStore.nomination.formOfRecognition,
        proposedFormOfRecognition: formStore.nomination.proposedFormOfRecognition,
        recommendedFormOfRecognition: formStore.nomination.recommendedFormOfRecognition,
        approvedFormOfRecognition: formStore.nomination.approvedFormOfRecognition,
        proposedAmount: formStore.nomination.proposedAmount,
        recommendedAmount: formStore.nomination.recommendedAmount,
        approvedAmount: formStore.nomination.approvedAmount,
        fiscalStrip: formStore.nomination.fiscalStrip,
        nominationEmployees: nomineesList,
        nominationAction: action,
        isAutoSave: formStore.nomination.isAutoSave,
        effectiveDate: formStore.nomination.effectiveDate,
      };

      console.log('nomination data to be saved:', nominationData);
      dispatch({ type: 'START_SAVE_REQUEST' });
      const response = await service.saveNomination(nominationData);
      if (response.status === responseStatus.BAD_REQUEST || response.status === responseStatus.UNPROCESSABLE_ENTITY) {
        dispatch({
          type: 'UPDATE_VALIDATION_RESULT',
          data: [response.data],
        });
      }
      if (response.ok && nominationData.nominationId === 0) {
        dispatch({ type: 'UPDATE_NEW_RECORD_ID', data: response.data.id });
      }

      dispatch({ type: 'COMPLETE_SAVE_REQUEST' });
      return response.ok;
    },

    // const addNote = () => {
    //   const note = {
    //     userEmployeeId: sessionStore.employeeUser.employeeId,
    //     userName: sessionStore.employeeUser.name,
    //     content: noteValue,
    //     createdDate: getDateUtcNow(),
    //     noteType: 'comment',
    //     noteState: 'new',
    //   };
    //   onClickAddNote(note);
    //   setNoteValue('');
    // };

    forceReload: () => dispatch({ type: 'FORCE_RELOAD' }),

    loadNomination: async () => {
      dispatch({ type: 'START_LOAD_NOMINATION' });
      const requestData = {
        userEmployeeId: employeeId,
        nominationId: typeof nominationId === 'string' ? parseInt(nominationId, 10) : nominationId,
        formMode: getFormMode(),
      };
      const response = await service.getNomination(requestData);

      if (response && response.ok) {
        console.log('response', response.data);
        dispatch({
          type: 'SET_NOMINATION',
          data: {
            nominationId: response.data.nominationId,
            nominationKey: response.data.nominationKey,
            isEditable: response.data.isEditable,
            isJustificationCitationEditable: response.data.isJustificationCitationEditable,
            isCycleVisible: response.data.isCycleVisible,
            isCycleEditable: response.data.isCycleEditable,
            isReasonAndTypeEditable: response.data.isReasonAndTypeEditable,
            isRecommendedAmountVisible: response.data.isRecommendedAmountVisible,
            isRecommendedAmountEditable: response.data.isRecommendedAmountEditable,
            isApprovedAmountVisible: response.data.isApprovedAmountVisible,
            isProposedAmountVisible: response.data.isProposedAmountVisible,
            isProposedAmountEditable: response.data.isProposedAmountEditable,
            isFiscalDataVisible: response.data.isFiscalDataVisible,
            isFiscalDataEditable: response.data.isFiscalDataEditable,
            canAddComments: response.data.canAddComments,
            isGroup: response.data.isGroup,
            nominationStatus: response.data.nominationStatus,
            nominationStatusName: response.data.nominationStatusName,
            nominatorUserId: response.data.nominatorId,
            nominatorName: response.data.nominatorName,
            nominatorPositionTitle: response.data.nominatorPositionTitle,
            missionId: response.data.missionId,
            missionName: response.data.missionName,
            postId: response.data.postId,
            postName: response.data.postName,
            sectionId: response.data.sectionId,
            sectionShortName: response.data.sectionShortName,
            awardReasons: response.data.awardReasons.map((item) => item.awardReasonId),
            awardTypeName: response.data.awardTypeName,
            otherReason: response.data.otherReason,
            awardTypeId: response.data.awardTypeId,
            awardProcessType: response.data.awardProcessType,
            loadedAwardProcessType: response.data.awardProcessType,
            cycleId: response.data.cycleId,
            bureauId: response.data.bureauId,
            bureauCycleId: response.data.bureauCycleId,
            justification: response.data.justification,
            citation: response.data.citation,
            formOfRecognition: response.data.formOfRecognition,
            proposedFormOfRecognition: response.data.proposedFormOfRecognition,
            recommendedFormOfRecognition: response.data.recommendedFormOfRecognition,
            approvedFormOfRecognition: response.data.approvedFormOfRecognition,
            proposedAmount: response.data.proposedAmount,
            recommendedAmount: response.data.recommendedAmount,
            approvedAmount: response.data.approvedAmount,
            periodOfPerformanceStartDate: response.data.periodOfPerformanceStartDate,
            periodOfPerformanceEndDate: response.data.periodOfPerformanceEndDate,
            fiscalStrip: response.data.fiscalStrip,
            nominees: response.data.nominationEmployees,
            approvers: response.data.nominationApprovers,
            usdhApprovalDate: response.data.usdhApprovalDate,
            notes: [],
            isJustificationSaved: false,
            isJustificationDirty: false,
            isCitationSaved: false,
            isCitationDirty: false,
            isAutoSave: false,
            returnToStatuses: response.data.returnToStatuses,
          },
        });
        dispatch({
          type: 'SET_AVAILABLE_ACTIONS_AND_CYCLES',
          data: {
            availableActions: response.data.availableActions,
            availableCycles: response.data.availableCycles ?? response.data.availableBureauCycles,
          },
        });
        if (formStore.isRightSideBarVisible) {
          helperFunctions.loadHistoryAndComments(true);
        }
      }
    },

    loadEmployeeHistory: async (nomineeId) => {
      dispatch({ type: 'OPEN_EMPLOYEE_HISTORY_MODAL' });
      const requestData = {
        missionId: isBureauUser ? missionId : 0 ,
        nomineeId,
      };
      const response = await service.getEmployeeHistory(requestData);
      if (response && response.ok) {
        console.log('loaded History: ', response.data);
        dispatch({
          type: 'UPDATE_EMPLOYEE_HISTORY',
          data: {
            employeeId: response.data.employeeId,
            name: response.data.name,
            currentPositionTitle: response.data.currentPositionTitle,
            previousPositionTitle: response.data.previousPositionTitle,
            dateOfHire: response.data.dateOfHire,
            ongoingNominationCount: response.data.ongoingNominationCount,
            awardsReceived: response.data.awardsReceived,
            grade: response.data.grade,
          },
        });
      }
    },

    generateCertificate: async (sealUrl, signatureUrl, signer, format) => {
      dispatch({ type: 'START_CERTIFICATE_GENERATION' });
      const selectedNominationIds = [getNominationId()];
      if (format === 'PDF') {
        await certificateService.generateCertificate(
          selectedNominationIds,
          currentMissionId,
          sealUrl,
          signatureUrl,
          signer
        );
      } else if (format === 'WORD') {
        await certificateService.generateWordCertificate(
          selectedNominationIds,
          currentMissionId,
          sealUrl,
          signatureUrl,
          signer
        );
      }
      dispatch({ type: 'COMPLETE_CERTIFICATE_GENERATION' });
    },

    loadHistoryAndComments: async (forceLoad) => {
      if (!forceLoad && formStore.nomination.history != null) return;

      dispatch({ type: 'START_LOAD_HISTORY' });
      const requestData = {
        userEmployeeId: employeeId,
        nominationId: typeof nominationId === 'string' ? parseInt(nominationId, 10) : nominationId,
        formMode: getFormMode(),
      };
      const response = await service.getNominationHistory(requestData);
      if (response && response.ok) {
        dispatch({ type: 'SET_HISTORY_AND_COMMENTS', data: response.data });
      }
    },

    onClickOpenAnytimeApproversModal: async () => {
      dispatch({ type: 'OPEN_NOMINATION_APPROVER_MODAL' });
      return [];
    },

    updateNomineeStatus: async (nomineeItemId, approveFlag, comment) => {
      const requestData = {
        userEmployeeId: employeeId,
        nominationEmployeeId: nomineeItemId,
        status: approveFlag,
        comment,
        formMode: getFormMode(),
      };
      dispatch({ type: 'START_SAVE_REQUEST' });
      const response = await service.updateNomineeStatus(requestData);
      dispatch({ type: 'COMPLETE_SAVE_REQUEST' });
      return response;
    },

    getNominationId,
    getMode,
    isEditMode,
    isNewMode,
    isViewMode,
    isApproveMode,
    isReviewMode,
    isDisapprovedMode,
    isFinancialInfoMode,
    isPendingEffectiveDateMode,
    isSendToOps,
    getTitle,
    isPrintIconVisible,
  };

  useEffect(() => {
    async function setNominatorDataForNewNomination() {
      const requestData = {
        userEmployeeId: employeeId,
        nominationId: 0,
        postId: sessionStore.employeeUser.postId,
      };
      if (!requestData.postId) {
        // eslint-disable-next-line no-alert
        alert('Post cannot be empty');
        return;
      }
      const response = await service.getNomination(requestData);

      if (response && response.ok) {
        dispatch({
          type: 'SET_NEW_NOMINATION',
          data: {
            nominationId: response.data.nominationId,
            isEditable: response.data.isEditable,
            isJustificationCitationEditable: response.data.isJustificationCitationEditable,
            isCycleVisible: response.data.isCycleVisible,
            isCycleEditable: response.data.isCycleEditable,
            isReasonAndTypeEditable: response.data.isReasonAndTypeEditable,
            isProposedAmountVisible: response.data.isProposedAmountVisible,
            isRecommendedAmountEditable: false,
            isProposedAmountEditable: false,
            nominationStatus: response.data.nominationStatus,
            nominationStatusName: response.data.nominationStatusName,
            nominatorUserId: sessionStore.employeeUser.employeeId,
            nominatorName: sessionStore.employeeUser.name,
            nominatorPositionTitle: sessionStore.employeeUser.positionTitle,
            missionId: sessionStore.currentMissionId,
            missionName: sessionStore.employeeUser.missionName,
            postId: sessionStore.employeeUser.postId,
            postName: sessionStore.employeeUser.postName,
            sectionId: sessionStore.employeeUser.sectionId,
            sectionShortName: sessionStore.employeeUser.sectionShortName,
            cycleId: 0,
            bureauCycleId: 0,
            bureauId: 0,
            awardReasonId: 0,
            awardReasonName: '',
            awardReasons: [],
            otherReason: null,
            awardTypeId: 0,
            awardTypeName: '',
            justification: '',
            citation: '',
            formOfRecognition: response.data.formOfRecognition,
            proposedAmount: 0,
            recommendedAmount: 0,
            approvedAmount: 0,
            fiscalStrip: '',
            nominees: [],
            notes: [],
          },
        });
        dispatch({
          type: 'SET_AVAILABLE_ACTIONS_AND_CYCLES',
          data: {
            availableActions: response.data.availableActions,
            availableCycles: response.data.availableCycles,
          },
        });
      }
    }
    if (
      sessionStore.employeeUser &&
      sessionStore.employeeUser.employeeId &&
      isNewMode() &&
      formStore.userSessionRequest === requestStatus.REQUEST_NOT_INITIATED
    ) {
      setNominatorDataForNewNomination();
    }
  }, [
    employeeId,
    formStore.userSessionRequest,
    isNewMode,
    service,
    sessionStore.employeeUser,
    sessionStore.currentMissionId,
  ]);

  useEffect(() => {
    async function loadReasonsAndTypes() {
      dispatch({ type: 'START_REQUEST_REASONS_AND_TYPES' });
      const response = await service.getReasonsAndTypes(currentMissionId);
      if (response && response.ok) {
        dispatch({
          type: 'SET_REASONS_AND_TYPES',
          data: {
            reasons: response.data.awardReasons,
            types: response.data.awardTypes,
          },
        });
      }
    }

    if (
      currentMissionId &&
      formStore.nominationLoadRequest === requestStatus.REQUEST_COMPLETED &&
      formStore.reasonsAndTypesRequest === requestStatus.REQUEST_NOT_INITIATED
    ) {
      loadReasonsAndTypes();
    }
  }, [formStore.nominationLoadRequest, formStore.reasonsAndTypesRequest, currentMissionId, service]);

  return { formStore, dispatch, helperFunctions };
}

export default useNominationForm;
