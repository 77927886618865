import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Skeleton } from 'primereact/skeleton';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUser,
  faIdBadge,
  faBuilding,
  faMapMarkedAlt,
  faHandPointer,
  faStar,
  faTrophyAlt,
  faUserFriends,
} from '@fortawesome/pro-regular-svg-icons';

import { TypeBadge } from 'components';
import { requestStatus, processType, approvalStatus, nominationStatus, nominationFormMode } from 'constants/index';
import { useFormatting } from 'hooks';
import { faComments } from '@fortawesome/pro-solid-svg-icons';
import uuid from 'react-uuid';

function NominationFormHeader({ formStore, helperFunctions, readOnly }) {
  const { formatCycle } = useFormatting();
  const {
    nominatorName,
    nominatorPositionTitle,
    postName,
    missionName,
    sectionShortName,
    awardReasons,
    otherReason,
    awardTypeId,
    awardProcessType,
    cycleId,
    bureauId,
    bureauCycleId,
    isCycleVisible,
    isCycleEditable,
    approvers,
    isReasonAndTypeEditable,
  } = formStore.nomination;

  const mode = helperFunctions.getMode();
  const isOngoingNomination = useCallback(() => mode === 'ongoing', [mode]);
  const isReviewMode = useCallback(() => mode === 'review', [mode]);

  const isProcessing =
    formStore.userSessionRequest !== requestStatus.REQUEST_COMPLETED &&
    formStore.nominationLoadRequest !== requestStatus.REQUEST_COMPLETED;

  const hasRejectedNominees =
    formStore.nomination.nominees.filter((item) => item.approvalStatus === approvalStatus.DISAPPROVED).length > 0;

  const getAwardReasonName = useCallback(
    (reasonId) => {
      const reason = formStore.reasons.find((item) => item.id === reasonId);
      if (reason == null) {
        return '';
      }
      return reason.isOtherReason ? `Other: ${otherReason || ''}` : reason.name;
    },
    [otherReason, formStore.reasons]
  );

  const getAwardTypeName = useCallback(
    (typeId) => {
      const type = formStore.types.find((item) => item.id === typeId);
      if (type == null) {
        return '';
      }
      return type.name;
    },
    [formStore.types]
  );

  const getCycles = useCallback(
    () =>
      formStore.availableCycles &&
      formStore.availableCycles.map((item) => ({
        value: item.id,
        label: formatCycle(item),
        committeeEndDate: item.committeeEndDate ?? item.votingEndDate,
        closingDate: item.closingDate,
      })),
    [formStore.availableCycles, formatCycle]
  );

  function renderNominatorInfo() {
    if (isProcessing) {
      return (
        <div className="mb-2">
          <Skeleton width="12rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
          <Skeleton width="10rem" className="mb-2" />
        </div>
      );
    }

    return (
      <div>
        <div>
          <FontAwesomeIcon icon={faUser} fixedWidth />
          <span className="ml-1">{nominatorName}</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faIdBadge} fixedWidth />
          <span className="ml-1">{nominatorPositionTitle}</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faBuilding} fixedWidth />
          <span className="ml-1">{sectionShortName}</span>
        </div>
        <div>
          <FontAwesomeIcon icon={faMapMarkedAlt} fixedWidth />
          <span className="ml-1">{`${missionName}/${postName}`}</span>
        </div>
      </div>
    );
  }

  function renderLinkButton(text, icon, editable) {
    if (editable)
      return (
        <div key={text}>
          <Button color="link" className="p-0 text-left" onClick={helperFunctions.onClickOpenReasonAndTypeModal}>
            {text && <FontAwesomeIcon icon={icon} className="mr-1" fixedWidth />}
            {text}
          </Button>
        </div>
      );

    return (
      <div key={text} className="text-left">
        {text && <FontAwesomeIcon icon={icon} className="mr-1" fixedWidth />}
        {text}
      </div>
    );
  }

  function renderReasonAndTypeModalAction() {
    if (
      (!readOnly && formStore.reasonsAndTypesRequest !== requestStatus.REQUEST_COMPLETED) ||
      (readOnly && formStore.nominationLoadRequest !== requestStatus.REQUEST_COMPLETED)
    ) {
      return (
        <div className="mb-2">
          <Skeleton width="10rem" className="mb-2" />
        </div>
      );
    }

    if (awardReasons.length === 0 && awardTypeId === 0 && !readOnly) {
      return (
        <Button color="link" className="p-0" onClick={helperFunctions.onClickOpenReasonAndTypeModal}>
          <FontAwesomeIcon icon={faHandPointer} fixedWidth />
          Click to Select
        </Button>
      );
    }

    return (
      <>
        <div>
          {awardReasons.map((reasonId) => (
            <div key={`reason_${reasonId}`}>
              {renderLinkButton(getAwardReasonName(reasonId), faStar, isReasonAndTypeEditable)}
            </div>
          ))}
        </div>
        <div className="d-flex">
          {renderLinkButton(getAwardTypeName(awardTypeId), faTrophyAlt, isReasonAndTypeEditable)}
          <div style={{ lineHeight: '18px' }}>
            <TypeBadge type={awardProcessType} />
          </div>
        </div>
      </>
    );
  }

  function renderNominationStatus() {
    if (isProcessing) {
      return <Skeleton width="12rem" className="mb-2" />;
    }
    return (
      <>
        <div>
          <Button
            color="link"
            className="p-0"
            onClick={helperFunctions.onClickShowHideComments}
            disabled={mode === 'view'}
          >
            <FontAwesomeIcon icon={faComments} size="lg" className="mr-1" />
            {`${formStore.isRightSideBarVisible ? 'Hide' : 'Show'} History & Comments`}
          </Button>
        </div>
        {/* {(helperFunctions.isApproveMode() || helperFunctions.isReviewMode() || helperFunctions.isDisapprovedMode()) && */}
        {(hasRejectedNominees && ![nominationFormMode.BUREAU_ONGOING, nominationFormMode.BUREAU_REVIEW].includes(mode)) && (
          <div>
            <Button color="link" className="p-0" onClick={helperFunctions.onClickShowHideRejectedNominees}>
              {`${formStore.isRejectedNomineesVisible ? 'Hide' : 'Show'} Disapproved Nominees`}
            </Button>
          </div>
        )}
      </>
    );
  }

  function renderCycleInfo() {
    const buffer = [];
    buffer.push(
      <h6 key="cycle_header" className="mt-0">
        Cycle
      </h6>
    );
    if (!isCycleVisible) {
      return buffer;
    }
    if (isProcessing) {
      buffer.push(<Skeleton key="skeleton_header" width="12rem" className="mb-2" />);
      return buffer;
    }
    if (awardProcessType !== processType.ANNUAL) {
      buffer.push(<div key="not_available_header">Not available</div>);
      return buffer;
    }

    buffer.push(
      <Dropdown
        key={formStore.nomination.bureauId > 0 ? bureauCycleId : cycleId}
        value={formStore.nomination.bureauId > 0 ? bureauCycleId : cycleId}
        options={formStore.nomination.nominationKey ? getCycles()?.filter((x) => new Date(x.committeeEndDate) >= new Date()) : getCycles()?.filter(x => new Date(x.closingDate) >= new Date())}
        onChange={(e) => { 
           if( formStore.nomination.bureauId > 0) {
            helperFunctions.onChangeBureauCycle(e.value)
            } else {
          helperFunctions.onChangeCycle(e.value)
        }}}
        className="w-100 mr-5"
        disabled={!isCycleEditable}
      />
    );
    return buffer;
  }

  function renderCycleOrApprovers() {
    if (awardProcessType === processType.ANYTIME && isOngoingNomination()) {
      return (
        <>
          <div className="mb-1">
            <strong>Approvers</strong>
          </div>
          <div className="max-h-100px overflow-auto scrollbar">
            {approvers.map((item) => (
              <>
                <div key={uuid()} />
                <snap>{item.approver}/</snap>
                <snap>{item.roleName}</snap>
              </>
            ))}
          </div>
        </>
      );
    }
    return renderCycleInfo();
  }

  function renderGroupOrIndividual() {
    if (
      formStore.reasonsAndTypesRequest !== requestStatus.REQUEST_COMPLETED ||
      formStore.nominationLoadRequest !== requestStatus.REQUEST_COMPLETED
    ) {
      return (
        <div className="mb-2">
          <Skeleton width="10rem" className="mb-2" />
        </div>
      );
    }
    let icon = faUser;
    let text = '';
    if (formStore.nomination.nominees.length === 0) {
      text = 'Individual/Group';
    }
    if (formStore.nomination.nominees.length === 1) {
      text = 'Individual';
    }
    if (formStore.nomination.isGroup) {
      icon = faUserFriends;
      text = 'Group';
    }

    return (
      <>
        <FontAwesomeIcon icon={icon} className="mr-1" fixedWidth />
        {text}
      </>
    );
  }

  return (
    <div className="row nominator-info">
      <div className="col-md-3">
        <h6 className="mt-0">Nominator</h6>
        {renderNominatorInfo()}
      </div>
      <div className="col-md-3">
        <h6 className="mt-0">Reason and Type</h6>
        {renderReasonAndTypeModalAction()}
        <div>{renderGroupOrIndividual()}</div>
      </div>
      <div className="col-md-3">{renderCycleOrApprovers()}</div>
      <div className="col-md-3">
        <h6 className="mt-0">Actions</h6>
        <div>{renderNominationStatus()}</div>
      </div>
    </div>
  );
}
NominationFormHeader.propTypes = {
  formStore: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
  readOnly: PropTypes.bool,
};
NominationFormHeader.defaultProps = {
  readOnly: false,
};

export default React.memo(NominationFormHeader);
