import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTrash,
  faTrashAlt,
  faAngleDoubleDown,
  faFileSignature,
  faUserMinus,
  faStream,
  faUserPlus,
  faPaperPlane,
  faGlobe,
} from '@fortawesome/pro-solid-svg-icons';
import {
  faEdit,
  faQuestionCircle,
  faUserEdit,
  faWatch,
  faCheckCircle,
  faUserCog,
  faPencil,
  faInfoCircle
} from '@fortawesome/pro-regular-svg-icons';
import ReactTooltip from 'react-tooltip';
import stylePropType from 'react-style-proptype';

library.add(
  faTrash,
  faTrashAlt,
  faAngleDoubleDown,
  faEdit,
  faFileSignature,
  faQuestionCircle,
  faUserEdit,
  faWatch,
  faUserCog,
  faPencil,
  faUserMinus,
  faStream,
  faUserPlus,
  faCheckCircle,
  faPaperPlane,
  faGlobe,
  faInfoCircle
);

function ActionButton({ icon, text, tooltip, className, style, onClick, disabled }) {
  const cssClass = className || 'btn btn-sm btn-outline-secondary';

  return (
    <Button type="button" className={cssClass} style={style} onClick={onClick} data-tip={tooltip} disabled={disabled}>
      {icon && <FontAwesomeIcon icon={icon} fixedWidth />}
      {text && <span className="ml-1">{text}</span>}
      {tooltip && <ReactTooltip effect="solid" />}
    </Button>
  );
}
ActionButton.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.array]),
  text: PropTypes.string,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  style: stylePropType,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};
ActionButton.defaultProps = {
  icon: null,
  text: null,
  tooltip: null,
  className: null,
  style: null,
  disabled: false,
  onClick: () => null,
};

export default React.memo(ActionButton);
