import React, {useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbsUp, faThumbsDown, faHandPaper, faUserSlash, faComment, faArrowDown } from '@fortawesome/pro-solid-svg-icons';

import { voteType } from 'constants/index';

function VotingButtons({ nomination, helperFunctions, onClickVote, canUserVote }) {
 const [lastClickedVote, setlastClickedVote] = useState()
 const [currentCommentButtonText, setCurrentCommentButtonText] = useState('Save Comments')
 const [commentMode, setCommentMode] = useState(false)

 function reset() {
    nomination.newComment='' 
    setCommentMode(false)
    setlastClickedVote(null)
    setCurrentCommentButtonText("Save Comments")
    
}

function handleButtonClick(vote) {
  if(!nomination.newComment) {
    if(vote ===voteType.DISAPPROVED ) setCurrentCommentButtonText("Save Comments and Disapprove")
      if(vote ===voteType.DOWNGRADED ) setCurrentCommentButtonText("Save Comments and Downgrade")
    if(vote ===voteType.ONHOLD  ) setCurrentCommentButtonText("Save Comments and put on hold")
    if(vote ===voteType.RECUSED ) setCurrentCommentButtonText("Save Comments and recuse")
    if(!nomination.newComment) setCommentMode(true)
  }
  setlastClickedVote(vote)
  onClickVote(nomination.id, vote)  
  nomination.newComment=''  
  
}

  function handleCommentsButton() {
    if(lastClickedVote ===voteType.DISAPPROVED && nomination.newComment) {      
      onClickVote(nomination.id, voteType.DISAPPROVED)
      setCurrentCommentButtonText("Save Comments")
      setCommentMode(false)
      nomination.newComment=''      
        return 
    }
    if(lastClickedVote ===voteType.ONHOLD && nomination.newComment) {      
      onClickVote(nomination.id, voteType.ONHOLD)
      setCurrentCommentButtonText("Save Comments")
      setCommentMode(false)
      nomination.newComment=''      
      return 
    }
    if(lastClickedVote ===voteType.RECUSED && nomination.newComment) {      
      onClickVote(nomination.id, voteType.RECUSED)

      setCurrentCommentButtonText("Save Comments")
      setCommentMode(false)
      nomination.newComment=''      
      return 
    }

    if(lastClickedVote === voteType.DOWNGRADED && nomination.newComment) {      
      onClickVote(nomination.id, voteType.DOWNGRADED)

      setCurrentCommentButtonText("Save Comments")
      setCommentMode(false)
      nomination.newComment=''      
      return 
    }

    onClickVote(nomination.id, voteType.NONE);
    setCommentMode(false)
    setCurrentCommentButtonText("Save Comments")
 }

  return (
    <>
      <div className="text-center my-1 min-h-24px">
        <strong>{nomination.activeVoteDescription}</strong>
      </div>
      <div className="text-center my-1">
        <Button
          outline={nomination.activeVote !== voteType.APPROVED}
          disabled={nomination.activeVote === voteType.APPROVED || !canUserVote}
          color="success"
          className="w-100 text-nowrap"
          size="sm"
          onClick={() => onClickVote(nomination.id, voteType.APPROVED)}
        >
          <FontAwesomeIcon icon={faThumbsUp} className="mr-2" />
          Approve
        </Button>
      </div>
      <div className="text-center my-1">
        <Button
          outline={nomination.activeVote !== voteType.DISAPPROVED}
          disabled={nomination.activeVote === voteType.DISAPPROVED || !canUserVote || commentMode}
          color="danger"
          className="w-100 text-nowrap"
          size="sm"
          onClick={() => {
            handleButtonClick(voteType.DISAPPROVED)
          }}
        >
          <FontAwesomeIcon icon={faThumbsDown} className="mr-2" />
          Disapprove
        </Button>
      </div>      
      <div className="text-center my-1">
        <Button
          outline={nomination.activeVote !== voteType.DOWNGRADED}
          disabled={nomination.activeVote === voteType.DOWNGRADED || !canUserVote || commentMode}
          color="info"
          className="w-100 text-nowrap"
          size="sm"
          onClick={() => {
            handleButtonClick(voteType.DOWNGRADED)
          }}
        >
          <FontAwesomeIcon icon={faArrowDown} className="mr-2" />
          Downgrade
        </Button>
      </div>
      <div className="text-center my-1">
        <Button
          outline={nomination.activeVote !== voteType.ONHOLD}
          disabled={nomination.activeVote === voteType.ONHOLD || !canUserVote || commentMode}
          color="secondary"
          className="w-100 text-nowrap"
          size="sm"
          onClick={() => {
            handleButtonClick(voteType.ONHOLD)
        }}
        >
          <FontAwesomeIcon icon={faHandPaper} className="mr-2" />
          On Hold
        </Button>
      </div>
      <div className="text-center my-1">
        <Button
          outline={nomination.activeVote !== voteType.RECUSED}
          disabled={nomination.activeVote === voteType.RECUSED || !canUserVote || commentMode}
          color="warning"
          className="w-100 text-nowrap"
          size="sm"
          onClick={() => handleButtonClick(voteType.RECUSED)} 
        >
          <FontAwesomeIcon icon={faUserSlash} className="mr-2" />
          Recuse
        </Button>
      </div>
      <div className="text-center my-1">
        <Button
          color="secondary"
          className="w-100 "
          size="sm"
          onClick={() => handleCommentsButton()}
          disabled={nomination.newComment === ''}
        >
          <FontAwesomeIcon icon={faComment} className="pr-1" />
          {currentCommentButtonText}
        </Button>
      </div>
      <div className="text-center">
        <Button
          color="link"
          size="sm"
          onClick={() => helperFunctions.onClickShowComments(nomination.id)}
          className="text-nowrap"
        >
          {`${nomination.showCommentBox ? 'Hide' : 'Show'} Comment`}
        </Button>
        <Button
          color="link"
          size="sm"
          onClick={() => reset()}
          className="text-nowrap"
        >
          Reset Comment
        </Button>
      </div>
    </>
  );
}
VotingButtons.propTypes = {
  nomination: PropTypes.object.isRequired,
  helperFunctions: PropTypes.object.isRequired,
  onClickVote: PropTypes.func.isRequired,
  canUserVote: PropTypes.bool.isRequired,
};

export default React.memo(VotingButtons);
