const voteType = {
  NONE: 0,
  APPROVED: 10,
  DISAPPROVED: 20,
  RECUSED: 30,
  ONHOLD: 40,
  DOWNGRADED: 50
};

export default voteType;
