/* eslint-disable no-restricted-syntax */
import { useHistory } from 'react-router-dom';

const targets = {
  home: '/Home',
  logout: '/Logout',
  new_nomination_form: '/Nomination/Form/New',
  edit_nomination_form: '/Nomination/Form/Edit/{id}',
  pending_review_form: '/Nomination/Form/Review/{id}',
  view_nomination_form: '/Nomination/Form/View/{id}',
  les_view_form: '/Nomination/Form/Lesview/{id}',
  finance_analyst_form: '/Nomination/Form/FinancialInfo/{id}',
  approve_nomination_form: '/Nomination/Form/Approve/{id}',
  ongoing_nomination_form: '/Nomination/Form/Ongoing/{id}',
  disapproved_form: '/Nomination/Form/Disapproved/{id}',
  completed_form: '/Nomination/Form/Completed/{id}',
  external_approval_form: '/Nomination/Form/ExternalApproval/{id}',
  send_to_ops_form: '/Nomination/Form/SendToOPS/{id}',

  my_nominations: '/Nomination/List/MyNominations',
  my_nominations_list: '/Nomination/List/MySubmissions',
  pending_approval_list: '/Nomination/List/SupervisorApproval',
  les_supervisor: '/Nomination/List/LocalSupervisor',
  supervisor_ongoing: '/Nomination/List/SupervisorOngoing',
  send_to_ops_list: '/Nomination/List/SendToOPS',
  ongoing_nominations_list: '/Nomination/List/OngoingNominations',
  finance_analyst_list: '/Nomination/List/PendingFinancialInfo',
  pending_review_list: '/Nomination/List/PendingReview',
  pending_usdh_list: '/Nomination/List/PendingUSDHApproval',
  disapproved_list: '/Nomination/List/Disapproved',
  ceremony_assignment: '/Nomination/List/CeremonyAssignment',
  completed_list: '/Nomination/List/Completed',
  external_approval_list: '/Nomination/List/ExternalApproval',
  bureau_decisioned_list: '/Nomination/List/BureauDecision',

  jcac_voting: '/Voting/Pending',
  jcac_signoff: '/Voting/SignOff',

  finance_analysis: '/Finance/Analysis',
  finance_officer_signoff: '/Signoff/Finance',
  exec_officer_signoff: '/SignOff/Exec',
  mgt_officer_signoff: '/SignOff/Management',

  anytime_approver_signoff: '/SignOff/AnytimeApprover',

  rpt_all_awards: '/Reports/AllAwards',
  rpt_time_off: '/Reports/TimeOff',
  rpt_certificates: '/Reports/Certificates',
  rpt_finance: '/Reports/Finance',
  rpt_exec: '/Reports/Executive',
  rpt_bureau: '/Reports/BureauAwards/all',
  rpt_bureau_approved: '/Reports/BureauAwards/approved',

  mission_profile: '/Admin/Mission/Profile/{key}',
  post_profile: '/Admin/PostProfile/Profile',
  ceremony: '/Admin/Mission/Ceremony/New',
  ceremony_list: '/Admin/Mission/CeremonyList',
  edit_post_profile: '/Admin/PostProfile/Edit/{key}',
  new_mission_specific_award: '/Admin/Mission/SpecificAwards/New',
  new_global_award: '/Admin/ManageAwards/GlobalAwards/New',
  edit_global_award: '/Admin/ManageAwards/GlobalAwards/Edit/{id}',
  edit_mission_specific_award: '/Admin/Mission/SpecificAwards/Edit/{id}',

  cycles_list: '/Admin/Cycle/List',
  cycle_settings: '/Admin/Cycle/Settings',
  impersonate: '/Admin/Impersonate',
  impersonate_proxy: '/Admin/Impersonate/Proxy',
  assign_proxy: '/Admin/AssignProxy',
  new_cycle: '/Admin/Cycle/New',
  edit_cycle: '/Admin/Cycle/Edit/{id}',
  edit_ceremony: '/Admin/Mission/Ceremony/Edit/{id}',
  awards_settings: '/Admin/Mission/AwardTypeSettings',
  assign_proxy_special: '/Admin/Mission/AssignProxySpecial',

  ongoing_bureau_nomination_form: '/Nomination/Form/BureauOngoing/{id}',
  pending_bureau_review_form: '/Nomination/Form/BureauReview/{id}',
  bureau_pending_review_list: '/Nomination/List/BureauPendingReview',
  bureau_ongoing_list: '/Nomination/List/BureauOngoing',
  bureau_cycles_list: '/Bureau/Coordinator/Cycle/List',
  bureau_voting: '/Bureau/Committee/Voting/Voting',
  bureau_signoff: '/Bureau/Committee/Voting/SignOff',
  bureau_new_cycle: '/Bureau/Coordinator/Cycle/New',
  bureau_edit_cycle: '/Bureau/Coordinator/Cycle/Edit/{id}',
  bureau_manage_roles: '/Admin/Bureaus/BureauManageRoles/{id}',
  my_assigned_roles: '/Employee/MyAssignedRoles',
  employee_search: '/Admin/Employee/Search/Mission',
  employee_sys_new: '/Admin/Employee/SysNew',
  employee_new: '/Admin/Employee/New',
  employee_edit: '/Admin/Employee/Edit/{id}',
  sysadm_employee_edit: '/Admin/Employee/SysEdit/{id}',
  employee_assign_roles: '/Admin/Employee/Edit/{id}/Roles',
  employees_by_supervisor: '/Admin/Employee/SupervisedBy/{id}',
  sysadm_employee_search: '/Admin/Employee/Search/SysAdmin',
  archived_employee: '/Admin/Employee/Archived',
  sysadm_manage_awards: '/Admin/ManageAwards/ManageAwards',
  sysadm_adminroles: '/Admin/AdminRoles',
  sysadm_sign_into_mission: '/Admin/SignIntoMission',
  sysadm_bureaus: '/Admin/Bureaus/Bureaus',

  not_authorized: '/NotAuthorized',
  not_authorized_oas: '/NotAuthorizedOAS',
  forbidden: '/Forbidden',
  landing: '/',
};

const useNavigationLinks = () => {
  const history = useHistory();
  const getURL = (key, params) => {
    let target = targets[key.toLowerCase()];
    if (typeof target !== 'string') {
      return '';
    }

    if (typeof params === 'object') {
      for (const prop in params) {
        if (Object.prototype.hasOwnProperty.call(params, prop)) {
          target = target.replace(`{${prop}}`, params[prop]);
        }
      }
    }
    return target;
  };

  const goBack = () => history.goBack();
  const goTo = (key, params) => history.push(getURL(key, params));
  const goToWithQueryParams = (key, params) => history.push(`${getURL(key)}?${params.key}=${params.value}`);
  const goToWithState = (key, params, state) => history.push(getURL(key, params), state);
  const goHome = () => goTo('home');
  const goLogout = () => goTo('logout');

  return { getURL, goBack, goHome, goTo, history, goToWithQueryParams, goToWithState, goLogout };
};

export default useNavigationLinks;
