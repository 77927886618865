import React from 'react';
import {
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
} from 'reactstrap';
import PropTypes from 'prop-types';
import { Button } from 'primereact/button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { faPrint } from '@fortawesome/pro-solid-svg-icons';
import { Skeleton } from 'primereact/skeleton';
import { useNominationForm } from 'hooks/nomination';
import { useNavigationLinks } from 'hooks';
import { formatDate } from 'utils/dateHelpers';
import { requestStatus, formOfRecognition, nominationStatus } from 'constants/index';
import { TypeBadge } from 'components';
import PrintFormModal from './PrintFormModal';

const loadingRows = new Array(3);
const rowLoadingTemplate = () => <Skeleton />;

function NominationHistoryModal({
  isModalOpened,
  onClickCloseModal,
  employeeHistory,
  employeeHistoryLoadRequest,
  hideNominessGender,
  nominationId,
}) {
  const { helperFunctions, formStore } = useNominationForm();

  const { getURL, history } = useNavigationLinks();
  const isLoaded =
    employeeHistoryLoadRequest === requestStatus.REQUEST_COMPLETED;

  const formatAwardedDate = (rowData) => {
    return formatDate(rowData.modifiedDate);
  };

  const awardProccessTypeBodyTemplate = (rowData) => {
    return (
      <div>
        <TypeBadge type={rowData.awardProccessType} />
      </div>
    );
  };

  const goToOngoing = () => {
    let onGoingURL = getURL('ongoing_nominations_list');
    if (employeeHistory.ongoingNominationCount) {
      onGoingURL += `?nomineeName=${employeeHistory.name}`;
    }
    history.push(onGoingURL);
  };

  const pdfFileTemplate = (rowData) => {
    const titleDesc = 'Print Nomination';
    return (
      <>
        {!hideNominessGender && (
          <Button
            className="px-2 py-1 p-button-link"
            onClick={() =>
              helperFunctions.onClickOpenPrintModal(
                rowData.nominationId,
                rowData.awardProccessType
              )
            }
          >
            <i title={titleDesc}>
              <FontAwesomeIcon icon={faPrint} />
            </i>
          </Button>
        )}

        <span className="ml-1">{rowData.nominationKey} - {rowData.nominationStatus === nominationStatus.COMPLETED ? 'Completed' : 'Ongoing'}</span>
      </>
    );
  };

  return (
    <>
      <Modal
        scrollable
        isOpen={isModalOpened}
        toggle={onClickCloseModal}
        size="xl"
        className="nomination-history-modal"
      >
        <ModalHeader toggle={onClickCloseModal}>
          Employee Awards History -{' '}
          {isLoaded &&
            (hideNominessGender === true ? `Nominee` : employeeHistory.name)}
        </ModalHeader>
        <ModalBody>
          <div className="card">
            <div className="p-grid p-formgrid p-d-flex">
              <div className="p-field p-md-12">
                <div className="row ml-2 mt-2">
                  Entry of Duty:
                  <span className="col-6 mr-5">
                    {!isLoaded && (
                      <Skeleton
                        width="5rem"
                        height="1.5rem"
                        className="p-mb-2"
                      />
                    )}
                    {isLoaded && formatDate(employeeHistory.dateOfHire)}
                  </span>
                  Ongoing Nominations:
                  <span>
                    {!isLoaded && (
                      <Skeleton
                        width="5rem"
                        height="1.5rem"
                        className="p-mb-2 ml-2"
                      />
                    )}
                  </span>
                  {isLoaded && employeeHistory.ongoingNominationCount > 0 ? (
                    <Button
                      label={employeeHistory.ongoingNominationCount}
                      onClick={() => goToOngoing()}
                      style={{ height: 21, paddingTop: '0px' }}
                      type="button"
                      className="btn btn-link"
                      disabled
                    />
                  ) : (
                    <Label className="ml-2">none</Label>
                  )}
                </div>
                <div className="row ml-2 mt-2 mb-2">
                  Current Position:
                  <span className="col-6 mr-5">
                    {!isLoaded && (
                      <Skeleton
                        width="15rem"
                        height="1.5rem"
                        className="p-mb-2 ml-2"
                      />
                    )}
                    {isLoaded && employeeHistory.currentPositionTitle}
                  </span>
                </div>
                <div className="row ml-2 mt-2">
                  Grade:
                  <span className="col-6 mr-5">
                    {!isLoaded && (
                      <Skeleton
                        width="15rem"
                        height="1.5rem"
                        className="p-mb-2 ml-2"
                      />
                    )}
                    {isLoaded && employeeHistory.grade}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <Row className="mt-4 ml-2">
            <h5>Awards Received</h5>
          </Row>
          {!isLoaded && (
            <DataTable value={loadingRows} className="p-datatable-striped">
              <Column
                field="awardTypeName"
                header="Nomination Type"
                body={rowLoadingTemplate}
              />
              <Column
                field="modifiedDate"
                header="Award Date"
                body={rowLoadingTemplate}
              />
              <Column
                field="nominationKey"
                header="ID"
                body={rowLoadingTemplate}
              />
            </DataTable>
          )}
          {isLoaded && (
            <DataTable
              value={employeeHistory.awardsReceived}
              className="p-datatable-striped history-datatable-result"
              scrollable
              autoLayout
              metaKeySelection={false}
              dataKey="nominationKey"
            >
              <Column field="awardTypeName" header="Nomination Type" />
              <Column
                field="modifiedDate"
                header="Award Date"
                body={formatAwardedDate}
              />
              <Column
                field="awardProccessType"
                header="Process Type"
                body={awardProccessTypeBodyTemplate}
              />
              <Column
                field="nominationKey"
                header="ID"
                body={pdfFileTemplate}
              />
            </DataTable>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            label="Close/Cancel"
            icon="pi pi-times-circle"
            className="p-button-primary min-w-150px"
            onClick={() => onClickCloseModal()}
          />
        </ModalFooter>
      </Modal>
      <PrintFormModal
        isModalOpened={formStore.isPrintFormModalOpened}
        onClickCloseModal={helperFunctions.onClickClosePrintModal}
        nominationId={formStore.nominationIdforPrintModal}
        awardProcessType={
          formStore.awardProcessTypeForPrintModal ??
          formStore.nomination.awardProcessType
        }
        isCertificateOnly={formStore.nomination?.formOfRecognition === formOfRecognition.CERTIFICATE_ONLY}
      />
    </>
  );
}

NominationHistoryModal.propTypes = {
  isModalOpened: PropTypes.bool.isRequired,
  onClickCloseModal: PropTypes.func.isRequired,
  employeeHistory: PropTypes.object.isRequired,
  employeeHistoryLoadRequest: PropTypes.number.isRequired,
  hideNominessGender: PropTypes.bool,
  nominationId: PropTypes.number,
};

NominationHistoryModal.defaultProps = {
  hideNominessGender: false,
  nominationId: 0,
};

export default NominationHistoryModal;
