/* eslint-disable import/prefer-default-export */
function getEnumName(enumValues, value) {
    const keys = Object.keys(enumValues);
    const values = Object.values(enumValues);

    /* eslint-disable-next-line no-plusplus */
    for (let i = 0; i < values.length; i++) {
      if (values[i] === value) {        
        return keys[i]; // Return the key if you need to use it elsewhere
      }
    }
  
    return null; // Return null if no match is found
}

function getEnumValue(enumValues, value) {
  const keys = Object.keys(enumValues);
  const values = Object.values(enumValues);

  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < keys.length; i++) {
    if (keys[i] === value) {        
      return values[i]; // Return the key if you need to use it elsewhere
    }
  }

  return null; // Return null if no match is found
}

function removeHtmlEntities(str) {
  // Replace newline characters
  let newStr = str.replace(/\n/g, '');

  // Replace common HTML entities
  const htmlEntities = {
      '&nbsp;': ' ',
      '&lt;': '<',
      '&gt;': '>',
      '&amp;': '&',
      '&quot;': '"',
      '&apos;': "'"
      // Add more entities if needed
  };

  // Use Object.entries and forEach to replace each HTML entity with its corresponding character
  Object.entries(htmlEntities).forEach(([entity, char]) => {
      const regex = new RegExp(entity, 'g');
      newStr = newStr.replace(regex, char);
  });

  return newStr;
}

export {getEnumName, getEnumValue, removeHtmlEntities};