import React, { useState, useContext } from 'react';
import { Button } from 'primereact/button';
import { roleType } from 'constants/index';
import { UserSearch } from 'components';
import { SessionContext, NotificationContext } from 'contexts';
import { useEmployeeService } from 'hooks/admin';
import { Dropdown } from 'primereact/dropdown';

function AssignProxy() {
  const [selectedUser, setSelectedUser] = useState({});
  const { sessionStore, sessionHelpers } = useContext(SessionContext);
  const { showError, showSuccess } = useContext(NotificationContext);
  const employeeService = useEmployeeService();
  const [selectedProxyRole, setSelectedProxyRole] = useState();
  const proxyRoles = [{key: roleType.ExecOfficer, name: 'EXEC Officer'}, {key: roleType.JCACChair, name: 'JCAC Chair'}];
  const assignProxy = async () => {
    if (selectedUser.employeeId === sessionStore.employeeUser.employeeId) {
      showError('You cannot proxy yourself!');
      return;
    }
    const assignData = {
      assignId: sessionStore.employeeUser.employeeId,
      proxyId: selectedUser.employeeId,
    };
    const response = await employeeService.assignProxy(assignData);
    if (response.ok) {
      showSuccess('You have successfully assigned a proxy user.');
    }
    await sessionHelpers.initSession();
  };

  const removeProxy = async () => {
    const response = await employeeService.removeProxy(sessionStore.employeeUser.employeeId);
    if (response.ok) {
      showSuccess('You have successfully removed your proxy user.');
    }
    await sessionHelpers.initSession();
  };

  function renderData(label, data) {
    return (
      <>
        <div className="row mt-3">
          <div className="col">{label}</div>
        </div>
        <div className="row">
          <div className="col">
            {!data && <span>&nbsp;</span>}
            {data && <strong>{data}</strong>}
          </div>
        </div>
      </>
    );
  }

  function renderSelectedUser() {
    return (
      <>
        {renderData('Name', selectedUser.name)}
        {renderData('Email', selectedUser.email)}
        {renderData('Post', selectedUser.postName)}
        {renderData('Section', selectedUser.sectionName)}
        {renderData('Position Title', selectedUser.positionTitle)}
      </>
    );
  }

  function renderProxy() {
    return (
      <>
        {renderData('Name', sessionStore.proxyUserName)}
        {renderData('Email', sessionStore.proxyUserEmail)}
      </>
    );
  }

  return (
    <>
      <h4>Assign Proxy</h4>
      {sessionStore.proxyUserId && (
        <>
          <div className="row">
            <div className="col-12">You have assigned following user as proxy.</div>
          </div>
          {renderProxy()}
        </>
      )}
      {!sessionStore.proxyUserId && (
        <>
          <div className="row">
            <div className="col-12">Find the user you want to assign as proxy</div>
            <div className="col-12">
              <UserSearch value={selectedUser} setValue={(user) => setSelectedUser(user)} className="max-w-500px" />
            </div>
          </div>
          {renderSelectedUser()}
          <div className="row">
            <div className="form-group col-3">Proxy Role     
              <Dropdown
                value={selectedProxyRole}
                options={proxyRoles}
                optionLabel="name"
                optionValue="key"
                className="w-100"
                onChange={(e) => setSelectedProxyRole(e.target.value)}
              /> 
            </div>
      </div>
        </>
      )}
      <div className="row mt-3">
        <div className="col-12">
          {sessionStore.proxyUserId && (
            <Button
              label="Remove Proxy"
              className="p-button-danger"
              icon="pi pi-times-circle"
              onClick={() => removeProxy()}
            />
          )}
          {!sessionStore.proxyUserId && (
            <Button
              label="Assign Proxy"
              icon="pi pi-save"
              className="p-button-primary"
              onClick={() => assignProxy(true)}
              disabled={!selectedUser.email}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default React.memo(AssignProxy);
