import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { UserSearch } from 'components';
import { SessionContext, NotificationContext } from 'contexts';
import { useNavigationLinks, useAuthorization } from 'hooks';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

function Impersonate() {
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedProxyedUser, setSelectedProxyedUser] = useState();
  const { sessionStore, sessionHelpers } = useContext(SessionContext);
  const { showError } = useContext(NotificationContext);
  const { proxyMode } = useParams();
  const { checkRole, checkPermission } = useAuthorization();
  const { navigationLinks, goTo } = useNavigationLinks();

  const isProxyMode =
    proxyMode !== undefined && proxyMode.toLowerCase() === 'proxy';

  const setProxyUserAsSelected = (employeeId) => {
    if (!employeeId) {
      setSelectedUser({});
      return;
    }
    const userToProxy = sessionStore.proxyingUsers.find(
      (item) => item.employeeId === employeeId
    );
    setSelectedUser(userToProxy);
  };

  const setImpersonation = async () => {
    if (selectedUser.employeeId === sessionStore.employeeUser.employeeId) {
      showError('You cannot impersonate yourself!');
      return;
    }
    sessionHelpers.startImpersonation(selectedUser.employeeId);
    goTo('home');
  };

  const stopImpersonation = async () => {
    sessionHelpers.terminateImpersonation();
    setSelectedUser({});
    goTo('home');
  };

  useEffect(() => {
    if (!sessionStore.isImpersonating) {
      if (!(checkRole('hr_officer') || checkPermission('proxy') || checkRole('bureau_coordinator'))) {
        goTo('not_authorized');
      }
    }
  }, [checkRole, goTo, sessionStore.isImpersonating, checkPermission]);

  function renderData(label, data) {
    return (
      <>
        <div className="row mt-3">
          <div className="col">{label}</div>
        </div>
        <div className="row">
          <div className="col">
            {!data && <span>&nbsp;</span>}
            {data && <strong>{data}</strong>}
          </div>
        </div>
      </>
    );
  }

  function renderSelectedUser() {
    return (
      <>
        {renderData('Name', selectedUser?.name)}
        {renderData('Email', selectedUser?.email)}
        {renderData('Post', selectedUser?.postName)}
        {renderData('Section', selectedUser?.sectionName)}
        {renderData('Position Title', selectedUser?.positionTitle)}
      </>
    );
  }

  function renderImpersonatedUser() {
    return (
      <>
        {renderData('Name', sessionStore.employeeUser.name)}
        {renderData('Email', sessionStore.employeeUser.email)}
      </>
    );
  }

  return (
    <>
      <h4>Sign in On Behalf {isProxyMode && '(Proxy)'}</h4>
      {sessionStore.isImpersonating && (
        <div className="row">
          <div className="col-12">
            You currently impersonating the following user
          </div>
        </div>
      )}
      {!sessionStore.isImpersonating && (
        <div className="row">
          <div className="col-12">
            <span>{isProxyMode ? 'Select' : 'Find'}</span> the user you want to
            sign in on behalf of:
          </div>
          <div className="col-12">
            {isProxyMode && (
              <Dropdown
                value={selectedProxyedUser}
                options={sessionStore.proxyingUsers}
                optionLabel="name"
                optionValue="employeeId"
                className="w-400px"
                showClear
                onChange={(event) => {
                  setSelectedProxyedUser(event.target.value);
                  setProxyUserAsSelected(event.target.value);
                }}
              />
            )}
            {!isProxyMode && (
              <UserSearch
                value={selectedUser}
                setValue={(user) => setSelectedUser(user)}
                className="w-400px"
                isGlobal={checkRole('global_impersonation')}
                bureauId={sessionStore.isBureauUser ? sessionStore.currentMissionId : 0}
              />
            )}
          </div>
        </div>
      )}
      {!sessionStore.isImpersonating && renderSelectedUser()}
      {sessionStore.isImpersonating && renderImpersonatedUser()}
      <div className="row mt-3">
        <div className="col-12">
          {sessionStore.isImpersonating && (
            <Button
              label="Stop Impersonation"
              icon="pi pi-sign-out"
              className="p-button-secondary"
              onClick={() => stopImpersonation()}
            />
          )}
          {!sessionStore.isImpersonating && (
            <Button
              label="Start Impersonation"
              icon="pi pi-sign-in"
              className="p-button-primary"
              onClick={() => setImpersonation(true)}
              disabled={!selectedUser?.employeeId}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default React.memo(Impersonate);
