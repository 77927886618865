const nominationFormMode = {
  NEW: 1,
  EDIT: 2,
  VIEW: 3,
  APPROVE: 4,
  REVIEW: 5,
  COMPLETED: 6,
  DISAPPROVED: 7,
  EXTERNAL_APPROVAL: 8,
  FINANCIAL_INFO: 9,
  LESVIEW: 10,
  SEND_TO_OPS: 11,
  SIGN_OFF: 12,
  ONGOING: 13,
  BUREAU_REVIEW: 14,
  BUREAU_ONGOING: 15,
  BUREAU_SIGNOFF: 16,  
  BUREAU_VOTING: 17,
  BUREAU_APPROVAL: 18,
};

export default nominationFormMode;
